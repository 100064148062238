.loading .skeleton {
	background: #E1E6EC;
	border-color: #E1E6EC;
	animation: opacityPulse-2 1.5s ease-out 0s;
	animation-iteration-count: infinite;
	height: 33px;
	margin: 5px 0;
}

.progress-header {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
    width: 466px;

	> div {
		margin: auto;
	}
	&.affiliates {
		grid-template-columns: repeat(6, 1fr);
		width: 490px
	}
}

.appName .appSubName {
	opacity: 1;
    font-size: 1.5rem;
    font-weight: 600;
}

.profile-pic {
	border-radius: 50%;
	border: 1px solid #DEDEDE;
	overflow: hidden;
	width: 65px;
	height: 65px;
	&.tumbnail {
		width: 33px;
		height: 33px;
	}
	img {
		width: 100%;
	}
}

.profile-icon i {
	width: 33px;
	height: 33px;
	font-size: 33px;
}

.auto-complete.loading, .text.loading  {
	width: 100%;
}

#resetPassword, #resetDuo, #updateEmail, #updateSponsor, #updateExpiry, #revokeInvite, #extendInvite, #revokeInvite {
	.modal-footer {
		justify-content: center
	}
}

.HuiTablePagination {
	div:nth-child(2) {
		width: 90px !important;
	}
}

.heroshot-layout .layout-wrapper .header {
	height: auto !important
}