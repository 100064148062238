.drawer { 
    .drawer-header {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: start;
    }
    .drawer-body .content {
        display: grid;
        grid-template-columns: 80%;
        justify-items: center;
        justify-content: center;
        grid-row-gap: 1rem;
        .dropzone.image {
            margin: 0;
            width: 50%;
            .controls {
                display: none;
            }
        }
        .crop-btn {
            a {
                display: grid;
                justify-items: center;
                background: #D8D8D8 !important;
                border-color: #D8D8D8 !important;
                color: #030303;
                &:hover {
                    background: #007BC2 !important;
                    border-color: #007BC2 !important;
                }
                i {
                    font-size: 30px;
                    width: 30px;
                    // color: #030303             
                }
            }
        }
        .reject-reasons div {
            width: 70%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            span {
                margin-bottom: 0.5rem;
                a {
                    border-radius: 1rem;
                }
            }
            // display: grid;
            // grid-template-columns: repeat(4, 1fr);
            // justify-items: center;
            // span:first-child {
            //     justify-self: end;
            // }
            // span:nth-child(4n) {
            //     justify-self: start;
            // }
            // span:nth-child(5n) {
            //     justify-self: end;
            // }
        }
    }
}
.user-profile {
    display: grid;
    grid-template-columns: 100px auto;
    grid-column-gap: 5px;
    .profile-icon {
        justify-self: center;
        i {
            font-size: 40px;
            width: 40px;
        }
    }
    .profile-pic {
        border-radius: 50%;
        border: 1px solid #DEDEDE;
        overflow: hidden;
        width: 80px;
        height: 80px;
        img {
            width: 100%;
        }
    }
    .profile-details {
        p {
            margin-bottom: 0;
        }
    }
    &.loading {
        .profile-details div {
            animation: opacityPulse-2 1.5s ease-out 1.5s;
            animation-iteration-count: infinite;
            border-radius: .6rem;
            text-indent: -9999px;
            border: .1875rem #fff solid;
            background-color: #E1E6EC;
            width: 50%;
        }
        .profile-icon {
            border-radius: .6rem;
            text-indent: -9999px;
            border: .1875rem #fff solid;
            background-color: #E1E6EC;
            animation: opacityPulse-2 1.5s ease-out 1.5s;
            animation-iteration-count: infinite;
            width: 100%;
        }
        .profile-details {
            div {
                &:nth-child(1) {
                    width: 80%;
                }
                &:nth-child(2) {
                    width: 65%;
                }
            }
        }
    }
}

.account-overview {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 0.6rem;
    border: 1px solid #DEDEDE;

    @media screen and (max-width: 566px) {
        grid-template-columns: repeat(1, 1fr);

        .card {
            grid-template-rows: auto !important;
        }
    }

    .card {
        border-radius: 0;
        align-content: start;
        grid-row-gap: 10px;
        grid-template-rows: 70px 80px auto;
        border-left: 0;
        border-top: 0;
        .top {
            display: grid;
            align-items: center;
        }
        .profile-pic {
            border-radius: 50%;
            border: 1px solid #DEDEDE;
            overflow: hidden;
            width: 65px;
            height: 65px;
            img {
                width: 100%;
            }
        }
        &:first-child {
            border-top-left-radius: 0.6rem;
            border-bottom-left-radius: 0.6rem;
        }
        &:nth-child(5) {
            border-top-right-radius: 0.6rem;
            border-bottom-right-radius: 0.6rem;
            border-right: 0;
        }
        .info {
            display: grid;
            justify-items: center;
            text-align: center;
        }
        .circle-bg {
            background-color: #079805;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            margin-bottom: 15px;
            i {
                color: #fff !important;
                font-size: 15px;
                margin-top: 8px;
            }
        }
        .top {
            i {
                // color: #079805;
                font-size: 30px;
                width: 30px;
                margin-bottom: 15px;
            }
        }
        display: grid;
        padding: 20px 10px;
        justify-items: center;
        p {
            margin-bottom: 0;
            word-break: break-word;
            text-align: center;
        }
        .bottom {
            display: grid;
            align-self: center;
            align-items: center;
            justify-items: center;
            grid-row-gap: 0.5rem;
            grid-template-rows: 45px 1fr;
        }
    }
    .onboarding, .mit-id, .mobile-id {
        display: grid;
        grid-template-columns: 3rem 1fr 1fr;
        grid-column: span 5;
        padding: 8px 15px;
        align-items: center;

        @media screen and (max-width: 566px) {
            grid-column: span 1;
        }
        .circular-progress-outer {
            height: 35px;
            width: 35px;
        }
        i {
            width: 30px;
            height: 30px;
            font-size: 30px;
            margin: 0px 3px;
        }
        h5 {
            margin: 0;
        }
        p {
            margin: 0;
            &:last-child {
                text-align: right;
            }
        }
    }
    .onboarding, .mobile-id {
        border-bottom: 1px solid #DFDFDF;
    }
}

.extra-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2rem;
    .sponsor, .expiry, .status {
        display: grid;
        grid-row-gap: 0.5rem;
        justify-items: center;
        position: relative;
        justify-content: center;
        p {
            margin: 0
        }
    }

    .status > span {
        align-self: flex-end;
    }

    &>div:not(:last-child):after {
        content: '';
            background-color: #DFDFDF;
            width: 2px;
            height: 100%;
            position: absolute;
            right: -1rem
    }
}

.search-box {
    display: grid;
    justify-content: center;
    margin-top: 6rem;
    margin-bottom: 1rem;
    &.invites {
        grid-template-columns: 70%;
        .content {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
    &.affiliates {
        grid-template-columns: 70%;
        .content {
            .top {
                display: grid;
                grid-column-gap: 0.5rem;
                grid-template-columns: 1fr;
            }
            .bot {
                display: grid;
                grid-column-gap: 0.5rem;
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    &.staff {
        grid-template-columns: 70%;
        .content {
            .top {
                display: grid;
                grid-column-gap: 0.5rem;
                grid-template-columns: 1fr;
            }
        }
    }
    &.students {
        grid-template-columns: 70%;
        .content {
            .top {
                display: grid;
                grid-column-gap: 0.5rem;
                grid-template-columns: 1fr;
            }
            .bot {
                display: grid;
                grid-column-gap: 0.5rem;
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
    .content {
        display: grid;
        justify-content: center;
        grid-row-gap: 0.5rem;
        .calendar-input {
            input::placeholder {
                color: #000;
            }
            input:-ms-input-placeholder {
                color: #000;
            }
            input::-ms-input-placeholder {
                color: #000;
            }
        }
    }
}

.onboarding-stats {
    .circular-progress-outer {
        width: 35px !important;
        height: 35px !important;
    }
    
    .circular-progress-inner {
        width: 29px !important;
        height: 29px !important;
        top: 3px !important;
        left: 3px !important;
    }
    
    .circular-progress-body {
        height: 23px !important;
        width: 23px !important;
        top: 3px !important;
        left: 3px !important;
    }
}

.drawer.right.drawer-medium {
    @media screen and (max-width: 1660px) {
        width: 60% !important;
    }
    @media screen and (max-width: 1360px) {
        width: 70% !important;
    }
    @media screen and (max-width: 1100px) {
        width: 80% !important;
    }

    @media screen and (max-width: 566px) {
        width: 95% !important;
    }
}