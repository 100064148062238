.heroshot-layout .content-area {
	.container .col-12 {
		padding-bottom: 4rem !important;
	}
}

.container {
	max-width: initial !important;
}

.list-table {
	display: grid;
	// &:nth-child() {
	//     border-top: 1px solid grey;
	// }
	p {
		margin: 0;
	}
	&.loading {
		.list-table-row {
			border: none !important;
		}
	}
	.list-table-header {
		display: grid;
		grid-template-columns: 2% 9% 9% 9% 9% 27% 9% 9% 9%;
		// grid-template-columns: 9% 9% 9% 9% 27% 9% 9% 9%;
		grid-column-gap: 1%;
		padding: 5px 0;
		color: #5b5b5b;
		font-weight: 500;
		align-items: center;
	}
	.list-table-body {
		.list-table-row {
			display: grid;
			grid-template-columns: 2% 9% 9% 9% 9% 27% 9% 9% 9%;
			// grid-template-columns: 9% 9% 9% 9% 27% 9% 9% 9%;
			grid-column-gap: 1%;
			padding: 5px 0;
			border-top: 1px solid #d1d1d1;
			font-weight: 500;
			align-items: center;
			&:last-child {
				border-bottom: 1px solid #d1d1d1;
			}
			div:first-child {
				color: #06214c;
			}
			a {
				cursor: pointer;
				color: #20447d;
				&:hover {
					color: #20447d;
				}
			}
		}
	}
}

.advanced-table .sort-icon .btn {
	padding: 0.3rem !important;
	margin-left: 0.2rem;

	&:hover {
		height: auto;
	}
}

.profile-icon {
	i {
		width: 32px;
		height: 32px;
		font-size: 32px;
		color: #d8d8d8;
	}
	img {
		max-width: 2.68rem;
		border-radius: 50%;
		height: 2rem;
		width: 2rem;
	}
}

.progress-dot {
	width: 0.9rem;
	height: 0.9rem;
	border-radius: 50%;
	margin: 0 auto;

	&.green {
		background-color: #029600;
	}

	&.red {
		// background-color: #dc3545;
		border: 1px solid #dc3545;
	}

	&.grey {
		background-color: #6c757d;
	}
	&.expired {
		background-color: #dc3545;
		border: 1px solid #dc3545;
	}
}

.account-progress {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 466px;
	&.affiliates {
		grid-template-columns: repeat(6, 1fr);
		width: 490px;
	}
	span {
		justify-self: center;
		line-height: 0;
		position: relative;
		i {
			font-size: 25px;
			width: 25px;
			&::before {
				position: relative;
				z-index: 2;
			}
		}

		.progress-dot {
			width: 0.9rem;
			height: 0.9rem;
			border-radius: 50%;

			&.green {
				background-color: #029600;
			}

			&.red {
				background-color: #dc3545;
			}

			&.grey {
				background-color: #6c757d;
			}
		}

		.circle-bg {
			background-color: #60ab7f;
			border-radius: 50%;
			width: 25px;
			height: 25px;
			i {
				color: #fff !important;
				font-size: 15px;
				margin-top: 5px;
			}
		}
		&.active {
			i {
				&::after {
					display: block;
					content: '';
					background-color: #89c09f;
					width: 72px;
					height: 3px;
					position: absolute;
					top: 50%;
					right: 25px;
					transform: translateY(-50%);
					z-index: 1;
				}
			}
			.circle-bg {
				i {
					&::after {
						display: block;
						content: '';
						// background-color: #89C09F;
						width: 72px;
						height: 3px;
						position: absolute;
						top: 50%;
						right: 25px;
						transform: translateY(-50%);
						z-index: 1;
					}
				}
			}
		}
		&:first-child {
			i {
				&::after {
					display: none;
				}
			}
		}
		&:nth-child(1) i {
			color: #aacdbc;
		}
		&:nth-child(2) i {
			color: #80b999;
		}
		&:nth-child(3) {
			i {
				color: #60ab7f;
			}
			.circle-bg i::after {
				background-color: #60ab7f !important;
			}
		}
		&:nth-child(4) {
			i {
				color: #49a06a;
			}
			.circle-bg i::after {
				background-color: #49a06a !important;
			}
		}
		&:nth-child(5) i {
			color: #079805;
		}
	}
}

.invitee-name,
a {
	font-weight: 600;
	cursor: pointer;
	color: #20447d;
	&:hover {
		color: #152b4e !important;
	}
}

ui5-table-cell {
	vertical-align: middle;
	.invitee-name,
	a {
		font-weight: 600;
		cursor: pointer;
		color: #20447d;
		&:hover {
			color: #152b4e !important;
		}
	}
}

.user-table {
	ui5-table-row ui5-table-cell:nth-child(1) {
		width: 32px;
	}

	ui5-table-row ui5-table-cell:nth-child(6) {
		width: 500px;
	}
}

// ui5-table-row ui5-table-cell:last-child, .action-buttons-container {
//     width: 350px;
// }

h1,
h2,
h3,
h4,
h5,
h6,
p {
	i {
		display: none !important;
	}
}

.graphs {
	display: grid;
	grid-template-columns: 90%;
	justify-content: center;
	.content {
		display: grid;
		grid-template-columns: auto auto;
		grid-column-gap: 1rem;
	}
	.recharts-tooltip-cursor {
		opacity: 0.5;
	}
	.circular-progress-body {
		background-color: #e0e5eb;
	}
}

.sidebar .nav-link:hover {
	color: #ffffff !important;
}

.HuiTableContainer {
	height: 55vh;
	overflow-y: scroll;
}
