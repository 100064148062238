.loader-page {
	position: absolute;
	z-index: 10000;
}

.skeleton-loader {
	margin-bottom: 0.5rem;
	animation: opacityPulse-2 1.5s ease-in-out 0.5s infinite;
	animation-iteration-count: infinite;
	display: block;
	background: rgba(0, 0, 0, 0.11);
	border-color: rgba(0, 0, 0, 0.11);
	border-radius: 0.6rem;
}
